import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
    fetchIRISViewsCount,
    fetchOrchestratorStatus,
    fetchViewsCount,
    triggerUpdateInIRISOrchestrator,
    triggerUpdateInOrchestrator
} from "../../../../../redux/actions/services";
import CircularLoader from "../../../../../components/Loaders/CircularLoader";
import CustomButton from "../../../../../components/CustomButton";
import useStyles from "../style";

function StatusDrawer({
  open, setOpen, orchestratorId, orchestratorType
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector(({ requests }) => get(requests, "queries.FETCH_ORCHESTRATOR_STATUS.data"));
  const [loading, setLoading] = useState(false);
  const [viewsSync, setViewsSync] = useState(null);

  const fetch = () => {
    setLoading(true);
    dispatch(fetchOrchestratorStatus(orchestratorId)).then(() => {
      setLoading(false);
      fetchViewsProgress();
    });
  };

  const resync = (target) => {
    setLoading(true);
    const triggerResync = orchestratorType === "iris" ? triggerUpdateInIRISOrchestrator : triggerUpdateInOrchestrator;
    dispatch(triggerResync(orchestratorId, target, orchestratorType)).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        if (res.data) {
          // eslint-disable-next-line no-alert
          alert("Resync was triggered successfully");
          fetch();
        } else {
          // eslint-disable-next-line no-alert
          alert("A resync was already done in the last hour");
        }
      } else {
        // eslint-disable-next-line no-alert
        alert("An error occured while trying to trigger resync");
      }
    });
  };

  useEffect(() => {
    if (open) fetch();
  }, [open]);

  const getStateIcon = (ok) => {
    if (ok) {
      return (
        <CheckCircleIcon className={classes.successIcon} fontSize="small" />
      );
    }
    return <ErrorIcon className={classes.errorIcon} fontSize="small" />;
  };

  const fetchViewsProgress = () => {
    setViewsSync(null);
    const fetchCount = orchestratorType === "iris" ? fetchIRISViewsCount : fetchViewsCount;
    dispatch(fetchCount(orchestratorId, onSuccessFetchViewsCount))
  }
  const onSuccessFetchViewsCount = (res) => {
      setViewsSync(res.data);
  }
  const getProgress = ({ view, recordsLag }) => {
    const recordsTotal = viewsSync[view];
    return ((recordsTotal - recordsLag) / recordsTotal) * 100
  };
  const getResync = (c) => {
    if (c.synchroRunning)
    {
      if (!viewsSync) return <CircularLoader size={25} />
      return (
        <>
          <LinearProgress />
          {getProgress(c) < 100
                && (
                <Typography variant="caption" color="textSecondary">
                  {`${Math.round(getProgress(c))}%`}
                </Typography>
                )}
        </>
      )
    }
    return (
      <CustomButton
            size="small"
            onClick={() => resync(c.source)}
            startIcon={<RefreshIcon />}
            disabled={!c.running || !c.taskRunning}
        >
        Resync
      </CustomButton>
    )
  }

  if (!open) return null;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        className: classes.drawerPaper,
      }}
    >
      <Box
        py={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" gridColumnGap={10}>
          <PlaylistAddCheckIcon />
          <Typography variant="h6" display="inline">
            {t("Orchestrator status")}
          </Typography>
        </Box>
        <CustomButton variant="outlined" size="small" onClick={fetch}>
          {t("sync.Refresh")}
        </CustomButton>
      </Box>
      <Box>
        {loading ? (
          <CircularLoader />
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {t("sync.General")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>{t("Database connection")}</TableCell>
                <TableCell align="right">
                  {getStateIcon(status?.dbConnectionSuccess)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>{t("Synchronization engine")}</TableCell>
                <TableCell align="right">
                  {getStateIcon(status?.clusterUp)}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {t("sync.Synchronization")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status?.connectors
              .filter((c) => c.source !== "deleted_record")
                .map((c, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{t(`sync.${c.source}`)}</TableCell>
                    <TableCell>
                      {getResync(c)}
                    </TableCell>
                    <TableCell align="right">
                      {getStateIcon(c.running && c.taskRunning)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Drawer>
  );
}

export default StatusDrawer;
