import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import {
  getLicense,
  licenseActivation,
} from "../../redux/actions/services/license";
import { useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { Container, Snackbar } from "@material-ui/core";
import { formatDateByLanguage } from "util";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Logo from "../../assets/logo.png"
import LanguageHeader from "../../components/Language";
import CustomButton from "../../components/CustomButton";

export default function License() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = React.useRef(null);
  const [fileName, setFileName] = useState();
  const [fileError, setFileError] = useState();
  const [file, setFile] = React.useState();
  const [licenseInfo, setLicenseInfo] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const onUpload = () => {
    const file = inputRef.current.files[0];
    setFile(file);
    setFileName(file?.name);
    !file?.name?.endsWith(".lic")
      ? setFileError(t("wrong.file.type"))
      : setFileError(null);
  };
  const onSuccess = (res) => {
    setLicenseInfo(res?.data);
  };
  const onError = (res) => {
    if (res.response.status !== 404) {
      setFileError(t(res.response.data.title))
      setError(res.response);
      setOpen(true);
    }
  };

  const goBackHome = () => {
    window.location.href = "/";
  };
  const onSubmit = () => {
    dispatch(licenseActivation(file, goBackHome, onError));
  };
  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  useEffect(() => {
    dispatch(getLicense(onSuccess, onError));
  }, []);

  return (
    <Container
      maxWidth="md"
      className={classes.container}
    >
      <Grid
        container
        className={classes.gridContainer}
        style={{ marginTop: "10%" }}
      >
        <Box flex={1} mt={1} ml={1} mb={8}>
          {licenseInfo && (
            <CustomButton
              variant="outlined"
              color="primary"
              size="small"
              onClick={goBackHome}
              startIcon={<ArrowBackIcon />}
              style={{ textTransform: "none" }}
            >
              {t("licence.goback.btn")}
            </CustomButton>
          )}
        </Box>
        <div className={classes.localeRoot}>
          <LanguageHeader />
        </div>
        <Grid item container xs={12}>
          <Grid item xs={12} className={classes.centred}>
            <img
              src={Logo}
              width={320}
              alt="SmartRoby logo"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              color="primary"
              className={classes.header}
              component="h3"
            >
              {t("activation")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={11} spacing={4}>
          {licenseInfo && (
            <Grid item xs={12}>
              <Alert
                  severity={!licenseInfo?.isExpire ? "success" : "error"}
                  className={classes.alertBody}
                  icon={false}
              >
                <Typography variant="h6" className={classes.alertInfo}>
                  {!licenseInfo?.isExpire
                    ? t("licence.validation.valid")
                    : t("licence.validation.expire")}
                </Typography>
                <b className={classes.licenseInfo}>{t("licence.id")}</b>
                :&nbsp;
                {licenseInfo?.id}
                <br />
                <b className={classes.licenseInfo}>{t("licence.licensee")}</b>
                :&nbsp;
                {licenseInfo?.licensee}
                <br />
                <b className={classes.licenseInfo}>{t("licence.start.at")}</b>
                :&nbsp;
                {formatDateByLanguage(licenseInfo?.startsAt, false)}
                <br />
                <b className={classes.licenseInfo}>{t("licence.end.at")}</b>
                :&nbsp;
                {formatDateByLanguage(licenseInfo?.endsAt, false)}
                <br />
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gridRowGap={8}>
              <Typography color="primary">{t("license")}</Typography>
              <TextField
                fullWidth
                name="license"
                id="license"
                variant="outlined"
                size="small"
                key={fileName}
                onClick={() => {
                  inputRef.current.click();
                }}
                value={fileName}
                error={fileError}
                helperText={fileError}
                placeholder={t("import your license here")}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      component="label"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <input
                        id="licenseUpload"
                        accept=".lic"
                        type="file"
                        hidden
                        ref={inputRef}
                        onChange={onUpload}
                      />
                      <CloudUploadIcon color="primary" />
                    </IconButton>
                  ),
                  readOnly: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.centred}>
            <CustomButton
              view="primary"
              type="submit"
              disabled={!fileName || fileError}
              onClick={onSubmit}
            >
              {t("activate")}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
      {error && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Box mt={3}>
            <Alert onClose={handleClose} severity="error">
              {t(error.data.message) || t(error.data.detail)}
            </Alert>
          </Box>
        </Snackbar>
      )}
    </Container>
  );
}
