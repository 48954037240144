import {
    FETCH_EXECUTIONS_ROBOTS,
    FETCH_PROCESS_ASSIGNED_ROBOTS,
    FETCH_QUEUE_ROBOTS,
    FETCH_ROBOT,
    FETCH_ROBOTS,
    FETCH_ROBOTS_BY_ORCHESTRATOR,
    FETCH_ROBOTS_BY_PROCESSES,
    UPDATE_ROBOT,
    UPDATE_ROBOT_STATUS,
    FETCH_PLANNER_FILTER_ROBOTS,
} from "../../constants";
import {
    URL_PROCESSES, URL_PROCESS_EXECUTION, URL_ROBOT, URL_SR_QUEUES_MNGT
} from "../../constants/endpoints";

export const fetchResourceList = (filter = {}) => {
    const {
        searchCriteria,
        orchestrator,
        pageNo,
        pageSize,
        status,
        sortBy,
        showDeleted,
        fleets
    } = filter;
    return {
        type: FETCH_ROBOTS,
        request: {
            url: encodeURI(`${URL_ROBOT}${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
            method: "GET",
            params: {
                page: pageNo,
                size: pageSize,
                sort: sortBy,
                status: status ? status.join() : null,
                showHidden: showDeleted,
                orchestrators: Array.isArray(orchestrator)
                    ? orchestrator.map((orch) => orch.id).join()
                    : [],
                fleetIds: fleets?.join()
            },
        },
    };
};
export const enableOrDisableResource = (id, resourceStatus, onSuccess, onError) => ({
    type: UPDATE_ROBOT_STATUS,
    request: {
        url: `${URL_ROBOT}${id}/${resourceStatus === "ACTIVE" ? "enable" : "disable"}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const updateResource = (resourceId, data) => ({
        type: UPDATE_ROBOT,
        request: {
            url: `${URL_ROBOT}${resourceId}`,
            method: "PUT",
            data,
        },
    });
export const fetchResourcesByOrchestrator = (idOrchestrator) => ({
    type: FETCH_ROBOTS_BY_ORCHESTRATOR,
    request: {
        url: `${URL_ROBOT}orchestrator/${idOrchestrator}`,
        method: "GET",
    },
});

export const fetchResource = (id) => ({
    type: FETCH_ROBOT,
    request: {
        url: `${URL_ROBOT}${id}`,
        method: "GET",
    },
});
export const editStatusResource = (resourcesIds, onSuccess, onError) => {
    if (typeof resourcesIds === "number") {
        resourcesIds = [resourcesIds]
    }
    return {
        type: "EDIT_ROBOTS_BY_ID",
        request: {
            url: `${URL_ROBOT}editStatus`,
            method: "put",
            params: {
                resourcesIds: resourcesIds.join(",")
            }
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                throw error;
            },
        },
    };
};
export const getQueuResources = (queueId) => ({
    type: FETCH_QUEUE_ROBOTS,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/${queueId}/resources`,
        method: "get",
    },
});
export const fetchAssignedResourcesByProcess = (processId) => ({
    type: FETCH_PROCESS_ASSIGNED_ROBOTS,
    request: {
        url: `${URL_PROCESSES}${processId}/resources/assigned`,
        method: "get",
    },
});
export const fetchResourcesByProcesses = (processesIds, fleetIds) => ({
    type: FETCH_ROBOTS_BY_PROCESSES,
    request: {
        url: `${URL_ROBOT}processes`,
        method: "get",
        params: {
            processesIds: processesIds?.join(","),
            fleetIds: fleetIds?.join(),
        },
    },
});
export const fetchExecutionsResources = (processIds = []) => ({
    type: FETCH_EXECUTIONS_ROBOTS,
    request: {
        url: `${URL_PROCESS_EXECUTION}resources`,
        method: "get",
        params: {
            processIds: processIds?.join(","),
        },
    },
});

export const fetchPlannerFilterResources = (idOrchestrateur) => ({
  type: FETCH_PLANNER_FILTER_ROBOTS,
  request: {
      url: `${URL_ROBOT}filter/planner`,
      params: {
        idOrchestrateur
      }
  },
});
